class LoginView {
   setGoogleAuthLink(authUrl) {
      const googleBtn = document.querySelector('.google');

      // Set the href attribute of the button to the authorize URL as the HTML does not have an href attribute
      googleBtn.setAttribute('href', authUrl);
   }

   createLoginEventListener(handler) {
      const loginForm = document.querySelector('form');
      const loginFormInputs = loginForm.querySelectorAll('input');

      loginForm.addEventListener('click', e => {
         e.preventDefault();

         if (!e.target.classList.contains('btn')) return;

         handler(e, loginForm, loginFormInputs);
      });
   }
}

export default new LoginView();
