import { showMessage, sendMessageToNextPage } from '../../helpers/messages';
import LoginModel from '../model/LoginModel';
import LoginView from '../view/LoginView';
import HeaderController from './HeaderController';
import { grabFormValues, checkFormForErrors } from '../../helpers/form';
import { renderSpinner } from '../../helpers/renderSpinner';
import waitInSeconds from '../../helpers/waitInSeconds';

class LoginController {
   async init() {
      // Pass true to redirect them to the dashboard if they're logged in
      HeaderController.init(true);

      this.#handleGoogleLoginLink();

      LoginView.createLoginEventListener(this.#loginFormSubmitted);
   }

   // TO DO: FIX REDIRECT URL ON GOOGLE CONSOLE AND THE SERVER
   async #handleGoogleLoginLink() {
      try {
         const googleAuthLink = await LoginModel.getGoogleAuthLink();

         if (googleAuthLink.statusCode !== 200)
            throw new Error(googleAuthLink.message);

         LoginView.setGoogleAuthLink(googleAuthLink.data.authUrl);
      } catch (err) {
         console.error(err.message);
         showMessage('negative', err.message);
      }
   }

   async #loginFormSubmitted(e, loginForm, loginFormInputs) {
      const errorsFound = checkFormForErrors(loginFormInputs);

      if (errorsFound) return;

      const formValues = grabFormValues(loginForm);

      renderSpinner(e.target, 'white');

      try {
         await waitInSeconds(1);

         const logUserIn = await LoginModel.logIn(formValues);

         if (logUserIn.statusCode !== 200) throw new Error(logUserIn.message);

         sendMessageToNextPage('positive', 'You have successfully logged in!');
         window.location.assign('/app.html#home');
      } catch (err) {
         console.error(err.message);
         showMessage('negative', err.message);
      }

      e.target.textContent = 'Log in';
   }
}

export default new LoginController();
