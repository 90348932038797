import { getJSON, postJSON } from '../../helpers/requests';
import { API_URL } from '../../config';

class LoginModel {
   async getGoogleAuthLink() {
      const googleURL = `${API_URL}/auth/google-url`;

      const googleAuthLink = await getJSON(googleURL);

      return googleAuthLink;
   }

   async logIn(formValues) {
      const loginURL = `${API_URL}/auth/log-in`;

      // Send the form values to the server
      const res = await postJSON(loginURL, formValues);

      return res;
   }
}

export default new LoginModel();
